import Repository from "@/lib/https/repository";
const urlInvoices = "/invoices";
const urlGetPlanInfo = "/companies/plan-information";
const urlStopStandardPlan = "/companies/stop-standard-plan";
const urlUpdatePaymentMethod = "/companies/update-payment-method";
const urlUpgradeCompanyToStandardPlan = "/companies/upgrade-to-standard-plan";
export default class InvoiceRepository extends Repository {
  getInvoices = async (year: string) => {
    const data = await this.axios.get(`${urlInvoices}?year=${year}`);
    if (data) return data.data;
    return {};
  };

  getPlanInfo = async () => {
    const data = await this.axios.get(`${urlGetPlanInfo}`);
    if (data) return data.data;
    return {};
  };

  updatePaymentMethod = async (formValues: FormData) => {
    const data = await this.axios.post(urlUpdatePaymentMethod, formValues);
    if (data) {
      return true;
    }
  };

  upgradeCompanyToStandardPlan = async (formValues: FormData) => {
    const data = await this.axios.post(
      urlUpgradeCompanyToStandardPlan,
      formValues
    );
    if (data) {
      return true;
    }
    return false;
  };

  stopStandardPlan = async () => {
    const data = await this.axios.post(urlStopStandardPlan);
    if (data) {
      return true;
    }
    return false;
  };
}
