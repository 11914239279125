import Repository from "@/lib/https/repository";

const urlSearchNewSignerToTransfer = "/users/search-users-for-signer-viewer";
const urlTransfer = "/contract-signers/transfer";
const urlGuestTransfer = "/guest/contract-signers/transfer";

export default class TransferRepository extends Repository {
  // {{host}}/users/search-users-for-signer-viewer?keyword=name&contractId=96928f22-2d78-4381-addb-4aef8760869e
  searchNewSignerToTransfer = async (keyword: string, contractId: string) => {
    const data = await this.axios.get(
      `${urlSearchNewSignerToTransfer}?keyword=${encodeURIComponent(
        keyword
      )}&contractId=${contractId}`
    );
    if (data) {
      return data.data;
    }
    return [];
  };

  // {{host}}/contract-signers/transfer
  transferSigners = async (formValues: FormData) => {
    const data = await this.axios.post(`${urlTransfer}`, formValues);
    if (data) {
      return data;
    }
    return false;
  };

  // {{host}}/guest/contract-signers/transfer
  guestTransferSigners = async (formValues: FormData) => {
    const data = await this.axios.post(`${urlGuestTransfer}`, formValues);
    if (data) {
      return data.data;
    }
    return false;
  };
}
