import Repository from "@/lib/https/repository";
import { SimpleBuiltInTemplate } from "@/models/TemplateAndContract";
import Pagination from "@/models/Pagination";

const customTemplate = "/folders-and-contracts/simple-list";
const builtinTemplate = "/contracts/builtin-templates";
const urlContract = "/contracts";
const urlRenameFile = `${urlContract}/rename-file`;
const urlContractMove = `${urlContract}/move`;
const urlMoveMany = `${urlContract}/move-many`;
const urlDeleteMany = `${urlContract}/delete-many`;
const urlSearchUsersAndGroups = "users-and-groups/simple-list";
const urlContractPermissions = `${urlContract}/permissions`;
const urlContractUpload = `${urlContract}/upload-file`;
const urlContractResendSignEmail = `${urlContract}/resend-sign-email`;
const urlContractMemberPermission = "/contract-member-permissions";
const urlContractGroupPermission = "/contract-group-permissions";
const urlContractAdvancedSearchField = "/contract-advanced-search-fields";
const urlSearchContracts = "/global-search-contracts";
const urlContractHistory = "/contracts/history";
const urlSearchUsersInAllCompanies = "/users/search-users-for-signer-viewer";
// for Sign
const urlContractFullInformation = "/contracts/full-information";
const urlRejectContract = "/contracts/reject";

export default class ContractRepository extends Repository {
  getCustomTemplate = async (payload: any) => {
    const data = await this.axios.get(
      `${customTemplate}?isTemplateFolder=${payload.isTemplateFolder}&keyword=${payload.keyword}&folderId=${payload.folderId}`
    );
    if (data) {
      return data;
    }
    return false;
  };

  getBuiltinTemplate = async ({
    folderId = "",
    orderBy = "",
    page = 1,
    pageSize = 100,
    keyword = "",
    bookmark=""
  }) => {
    let urlBuildIn = `${builtinTemplate}`;
    urlBuildIn = `${urlBuildIn}?page=${page}&pageSize=${pageSize}`;
    if (folderId !== "") urlBuildIn = urlBuildIn + `&folderId=${folderId}`;
    if (keyword !== "")
      urlBuildIn = urlBuildIn + `&keyword=${encodeURIComponent(keyword)}`;
    if (orderBy !== "") urlBuildIn = urlBuildIn + `&orderBy=${orderBy}`;
    if (bookmark !== "") urlBuildIn = urlBuildIn + `&bookmark=${bookmark}`;
    const data = await this.axios.get<Pagination<SimpleBuiltInTemplate>>(
      `${urlBuildIn}`
    );
    if (data) {
      return data;
    }
    return false;
  };

  // Search
  getAdvancedSearchFields = async () => {
    const data = await this.axios.get(urlContractAdvancedSearchField);
    if (data) return data.data;
    return {};
  };

  searchWithKeyword = async (keyword: string) => {
    const data = await this.axios.post(
      `${urlSearchContracts}?keyword=${encodeURIComponent(keyword)}`
    );
    if (data) return data.data;
    return {};
  };

  searchWithAdvancedField = async (advancedFields: never[]) => {
    const payload = advancedFields.map(
      (item) =>
        ({
          field: item["autoUpdate"],
          condition: item["condition"],
          value: item["value"],
        } as never)
    );
    const data = await this.axios.post(`${urlSearchContracts}`, payload);
    if (data) return data.data;
    return {};
  };

  // Contract History
  getContractHistory = async (id: string) => {
    const data = await this.axios.get(`${urlContractHistory}/${id}`);
    if (data) return data.data;
    return {};
  };

  getGuestContractHistory = async () => {
    const data = await this.axios.get(`/guest${urlContractHistory}`);
    if (data) return data.data;
    return {};
  };

  // digital/contract/	search for signers	{{host}}/users/search-users-for-signer-viewer
  getSearchUsersInAllCompanies = async (
    page = 1,
    pageSize = 10,
    keyword: string
  ) => {
    const data = await this.axios.get(
      `${urlSearchUsersInAllCompanies}?page=${page}&pageSize=${pageSize}&keyword=${encodeURIComponent(
        keyword
      )}`
    );
    if (data) {
      return data.data;
    }
    return [];
  };

  //get Contract full information
  getContractFullInformation = async (contractId: string, accessCode = "") => {
    const data = await this.axios.get(
      `${urlContractFullInformation}/${contractId}?accessCode=${accessCode}`
    );
    if (data) return data.data;
    return false;
  };

  //get Contract Info Guest
  getGuestContractInformation = async (accessCode = "") => {
    const data = await this.axios.get(
      `/guest${urlContractFullInformation}?accessCode=${accessCode}`
    );
    if (data) return data.data;
    return false;
  };

  //delete Contract
  deleteContract = async (contractId: string) => {
    const response = await this.axios.delete(`${urlContract}/${contractId}`);
    if (response) {
      return response;
    }
    return false;
  };

  //move Contract
  moveContract = async (params: {
    id: string;
    folderId: string | null;
  }) => {
    const data = await this.axios.put(urlContractMove, params);
    if (data) return data;
    return false;
  };

  //move many Contract
  moveManyContractFolder = async (params: {
    ids: Array<string>;
    folderIds: Array<string>;
    folderId: string | null;
    isTemplateFolder: boolean;
  }) => {
    const { data } = await this.axios.put(urlMoveMany, params);
    if (data) return data;
    return false;
  };

  //reject Contract
  rejectContract = async (contractId: string) => {
    const data = await this.axios.post(`${urlRejectContract}/${contractId}`);
    if (data) {
      return data;
    }
    return false;
  };

  // search user and group
  getUsersAndGroups = async ({
    page = 1,
    pageSize = 100,
    keyword = "",
    contractId = "",
  }) => {
    let urlBuildIn = `${urlSearchUsersAndGroups}?page=${page}&pageSize=${pageSize}`;
    if (keyword) {
      urlBuildIn = `${urlBuildIn}&keyword=${encodeURIComponent(keyword)}`;
    }
    if (contractId) {
      urlBuildIn = `${urlBuildIn}&contractId=${contractId}`;
    }
    const { data } = await this.axios.get(urlBuildIn);
    if (data) return data;
    return false;
  };

  // get Contract permission
  getContractPermissions = async (folderId: string) => {
    const { data } = await this.axios.get(
      `${urlContractPermissions}?contractId=${folderId}`
    );
    if (data) return data;
    return false;
  };

  // rename file
  renameFile = async (payload: { id: string; title: string }) => {
    const data = await this.axios.put(urlRenameFile, payload);
    if (data) return data;
    return false;
  };

  // delete many folder contract
  deleteManyFolderContract = async (payload: any) => {
    if (payload) {
      const response = await this.axios.delete(urlDeleteMany, {
        data: payload,
      });
      if (response) return response;
      return false;
    }
    return false;
  };

  addContractMemberGroupPermission = async (item: any) => {
    let response: any;
    if (item.userId) {
      response = await this.axios.post(urlContractMemberPermission, item);
    } else if (item.groupId) {
      response = await this.axios.post(urlContractGroupPermission, item);
    }
    if (response) return response.data;
    return false;
  };

  updateContractGroupMemeberPermission = async (item: any) => {
    let response: any;
    if (item.userId) {
      response = await this.axios.put(urlContractMemberPermission, item);
    } else if (item.groupId) {
      response = await this.axios.put(urlContractGroupPermission, item);
    }
    if (response) return response.data;
    return false;
  };

  deleteContractGroupMemeberPermission = async (contract: any, item: any) => {
    let response: any;
    if (item.userId) {
      response = await this.axios.delete(
        `${urlContractMemberPermission}/${contract.id}/${item.id}`
      );
    } else if (item.groupId) {
      response = await this.axios.delete(
        `${urlContractGroupPermission}/${contract.id}/${item.id}`
      );
    }
    if (response) return true;
    return false;
  };

  uploadContract = async (formValues: FormData) => {
    const data = await this.axios.post(urlContractUpload, formValues);
    if (data) return data;
    return false;
  };

  resendSignEmail = async (contractId: string) => {
    const data = await this.axios.post(`${urlContractResendSignEmail}/${contractId}`);
    if (data) return data;
    return false;
  }
}
