import Repository from "@/lib/https/repository";

const urlGroupList = "/groups";
const urlSimpleGroupList = "/groups/simple-list";

const urlUserList = "/users";
const urlMemberList = "/groups/list-members";
const urlAddMember = "/groups/add-members";

const urlGroupFolder = "/groups/permissions";
const urlGroupFolderAdd = "/groups/add-permissions";

const urlGroupFolderAction = "/folder-group-permissions";
const urlGroupContractAction = "/contract-group-permissions";

const urlDeleteMember = "/group-members";

export default class GroupRepository extends Repository {
  //Group List
  getGroupList = async (page = 1, pageSize = 10, keyword = "", bookmark = "") => {
    const data = await this.axios.get(
      `${urlGroupList}?page=${page}&pageSize=${pageSize}&keyword=${encodeURIComponent(
        keyword
      )}&bookmark=${bookmark}`
    );
    if (data) return data.data;
    return {};
  };

  getDetailGroup = async (groupId: string) => {
    const data = await this.axios.get(`${urlGroupList}/${groupId}`);
    if (data) return data.data;
    return null;
  };

  deleteGroup = async (groupID: string) => {
    const data = await this.axios.delete(`${urlGroupList}/${groupID}`);

    if (data) return data;
    return false;
  };

  updateGroup = async (group: any) => {
    const data = await this.axios.put(`${urlGroupList}`, group);

    if (data) return data.data;
    return false;
  };

  // Group Add
  getUserList = async ({
    groupId = "",
    page = 1,
    pageSize = 10,
    keyword = "",
  }) => {
    let builtInUrl = `${urlUserList}?page=${page}&pageSize=${pageSize}`;
    if (groupId !== "") {
      builtInUrl = builtInUrl + `&groupId=${groupId}`;
    }
    if (keyword !== "") {
      builtInUrl = builtInUrl + `&keyword=${encodeURIComponent(keyword)}`;
    }

    const data = await this.axios.get(builtInUrl);
    if (data) return data.data;
    return {};
  };

  createGroup = async (payload: any) => {
    const data = await this.axios.post(`${urlGroupList}`, payload);

    if (data) return data;
    return false;
  };

  //GroupMember

  getMemberGroup = async (groupID: string, page = 1, pageSize = 10) => {
    const data = await this.axios.get(
      `${urlMemberList}/${groupID}?page=${page}&pageSize=${pageSize}`
    );

    if (data) return data.data;
    return false;
  };

  addMembers = async (groupID: string, members: any) => {
    const data = await this.axios.post(`${urlAddMember}/${groupID}`, members);

    if (data) return data;
    return false;
  };

  deleteMember = async (groupID: string, userID: string) => {
    const data = await this.axios.delete(
      `${urlDeleteMember}/${groupID}/${userID}`
    );

    if (data) return data;
    return false;
  };

  //GroupFolder

  getItemsGroup = async ({
    isTemplateFolder = false,
    page = 1,
    pageSize = 10,
    groupId = "",
    keyword = "",
  }) => {
    const { data } = await this.axios.get(
      `${urlGroupFolder}/${groupId}?page=${page}&pageSize=${pageSize}&isTemplateFolder=${isTemplateFolder}&keyword=${encodeURIComponent(
        keyword
      )}`
    );
    if (data) return data;
    return false;
  };

  addFolderItemsGroup = async (groupId: string, payload: any) => {
    const data = await this.axios.post(
      `${urlGroupFolderAdd}/${groupId}`,
      payload
    );

    if (data) return data.data;
    return false;
  };

  updateFolderPermission = async (payload: any) => {
    const data = await this.axios.put(urlGroupFolderAction, payload);

    if (data) return true;
    return false;
  };

  deleteGroupFolder = async (groupId: string, folderId: string) => {
    const data = await this.axios.delete(
      `${urlGroupFolderAction}/${folderId}/${groupId}`
    );

    if (data) return true;
    return false;
  };

  updateContractPermission = async (payload: any) => {
    const data = await this.axios.put(urlGroupContractAction, payload);

    if (data) return true;
    return false;
  };

  deleteGroupContract = async (groupId: string, contractId: string) => {
    const data = await this.axios.delete(
      `${urlGroupContractAction}/${contractId}/${groupId}`
    );

    if (data) return true;
    return false;
  };

  // Simple group list
  getSimpleGroupList = async (page = 1, pageSize = 10, keyword = "") => {
    const data = await this.axios.get(
      `${urlSimpleGroupList}?page=${page}&pageSize=${pageSize}&keyword=${encodeURIComponent(
        keyword
      )}`
    );
    if (data) return data.data;
    return {};
  };
}
