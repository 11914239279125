import Repository from "@/lib/https/repository";

const urlContact = "/contacts";
const urlHideContact = "/contacts/hide";
const urlUnHideContact = "/contacts/un-hide";

export default class ContactRepository extends Repository {
  getContactList = async (
    isAllContacts = true,
    page = 1,
    pageSize = 10,
    keyword = "",
    bookmark = ""
  ) => {
    const data = await this.axios.get(
      `${urlContact}?page=${page}&pageSize=${pageSize}&keyword=${encodeURIComponent(
        keyword
      )}&allContacts=${isAllContacts}&bookmark=${bookmark}`
    );
    if (data) {
      return data.data;
    }
    return [];
  };

  createContact = async (formData: FormData) => {
    const body = JSON.stringify(Object.fromEntries(formData));
    const data = await this.axios.post(urlContact, body);

    if (data) return data.data;
    return false;
  };

  editContact = async (formData: FormData) => {
    const body = JSON.stringify(Object.fromEntries(formData));
    const data = await this.axios.put(urlContact, body);
    if (data) {
      return data.data;
    }
    return false;
  };

  hideContact = async (contactID: string) => {
    const data = await this.axios.post(`${urlHideContact}/${contactID}`);

    if (data) {
      return true;
    }
    return false;
  };

  unHideContact = async (contactID: string) => {
    const data = await this.axios.post(`${urlUnHideContact}/${contactID}`);
    if (data) return true;
    return false;
  };

  deleteContact = async (contactID: string) => {
    const data = await this.axios.delete(`${urlContact}/${contactID}`);
    if (data) return true;
    return false;
  };
}
