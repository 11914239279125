import Repository from "@/lib/https/repository";
import { FolderI } from "@/models/Folder";

const urlFolder = "/folders";
const urlFolderRename = `${urlFolder}/rename`;
const urlFolderPermissions = `${urlFolder}/permissions`;
const urlListFolder = `${urlFolder}/simple-list`;
const urlListFolderContract = "/folders-and-contracts";
const urlSearchUsersAndGroups = `users-and-groups/simple-list`;
const urlFolderGroupPermission = "folder-group-permissions";
const urlFolderMemberPermission = "folder-member-permissions";
const urlFolderMove = `${urlFolder}/move`;

export default class FolderRepository extends Repository {
  createFolder = async (folder: FolderI) => {
    const data = await this.axios.post<FolderI>(urlFolder, folder);
    if (data) return data;
    return false;
  };

  renameFolder = async (params: { id: string; name: string; isTemplateFolder: boolean }) => {
    const data = await this.axios.put<FolderI>(urlFolderRename, params);
    if (data) return data;
    return false;
  };

  deleteFolder = async (folderId: string) => {
    if (folderId) {
      const response = await this.axios.delete<FolderI>(
        `${urlFolder}/${folderId}`
      );
      if (response) return response;
      return false;
    }
  };

  getUsersAndGroups = async ({
    page = 1,
    pageSize = 100,
    keyword = "",
    folderId = "",
  }) => {
    let urlBuildIn = `${urlSearchUsersAndGroups}?page=${page}&pageSize=${pageSize}`;
    if (keyword) {
      urlBuildIn = `${urlBuildIn}&keyword=${encodeURIComponent(keyword)}`;
    }
    if (folderId) {
      urlBuildIn = `${urlBuildIn}&folderId=${folderId}`;
    }
    const { data } = await this.axios.get(urlBuildIn);
    if (data) return data;
    return false;
  };

  getListFolderContract = async ({
    page = 1,
    pageSize = 100,
    isTemplateFolder = false,
    folderId = "",
    keyword = "",
    withParentFolderPath = false,
    groupId = "",
    isSimple = false,
    orderBy = "",
    status = -1,
    bookmark = ""
  }) => {
    let urlBuildIn = "";
    if (isSimple) urlBuildIn = `${urlListFolderContract}/simple-list`;
    else urlBuildIn = urlListFolderContract;

    urlBuildIn = `${urlBuildIn}?page=${page}&pageSize=${pageSize}`;

    if (isTemplateFolder) urlBuildIn = urlBuildIn + "&isTemplateFolder=true";
    if (folderId !== "") urlBuildIn = urlBuildIn + `&folderId=${folderId}`;
    if (keyword !== "")
      urlBuildIn = urlBuildIn + `&keyword=${encodeURIComponent(keyword)}`;
    if (withParentFolderPath)
      urlBuildIn = urlBuildIn + `&withParentFolderPath=true`;
    if (groupId !== "") urlBuildIn = urlBuildIn + `&groupId=${groupId}`;
    if (orderBy !== "") urlBuildIn = urlBuildIn + `&orderBy=${orderBy}`;
    if (bookmark !== "") urlBuildIn = urlBuildIn + `&bookmark=${bookmark}`;
    if (status !== -1) urlBuildIn = urlBuildIn + `&status=${status}`;
    const { data } = await this.axios.get(`${urlBuildIn}`);
    if (data) return data;
    return false;
  };

  getFolderPermissions = async (folderId: any) => {
    const { data } = await this.axios.get(
      `${urlFolderPermissions}?folderId=${folderId}`
    );
    if (data) return data;
    return false;
  };

  updateFolderGroupMemeberPermission = async (data: any) => {
    let response: any;
    if (data.userId) {
      response = await this.axios.put(urlFolderMemberPermission, data);
    } else if (data.groupId) {
      response = await this.axios.put(urlFolderGroupPermission, data);
    }
    if (response) return response.data;
    return false;
  };

  deleteFolderGroupMemeberPermission = async (folder: any, item: any) => {
    let response: any;
    if (item.userId) {
      response = await this.axios.delete(
        `${urlFolderMemberPermission}/${folder.id}/${item.id}`
      );
    } else if (item.groupId) {
      response = await this.axios.delete(
        `${urlFolderGroupPermission}/${folder.id}/${item.id}`
      );
    }
    if (response) return true;
    return false;
  };

  addFolderMemberGroupPermission = async (item: any) => {
    let response: any;
    if (item.userId) {
      response = await this.axios.post(urlFolderMemberPermission, item);
    } else if (item.groupId) {
      response = await this.axios.post(urlFolderGroupPermission, item);
    }
    if (response) return response.data;
    return false;
  };

  getListFolder = async ({
    page = 1,
    pageSize = 100,
    keyword = "",
    folderId = "",
    isTemplateFolder = false,
  }) => {
    let urlBuildIn = `${urlListFolder}?page=${page}&pageSize=${pageSize}`;
    if (keyword) {
      urlBuildIn = `${urlBuildIn}&keyword=${encodeURIComponent(keyword)}`;
    }
    if (folderId) {
      urlBuildIn = `${urlBuildIn}&folderId=${folderId}`;
    }
    if (isTemplateFolder) {
      urlBuildIn = `${urlBuildIn}&isTemplateFolder=true`;
    }
    const { data } = await this.axios.get(urlBuildIn);
    if (data) return data;
    return false;
  };

  moveFolder = async (params: {
    id: string;
    parentFolderId: string | null;
  }) => {
    const data = await this.axios.put<FolderI>(urlFolderMove, params);
    if (data) return data;
    return false;
  };
}
