import Repository from "@/lib/https/repository";
import { FormContext } from "vee-validate";
import axios from "axios";

interface AddressResponse {
  code: number;
  data: {
    pref: string;
    address: string;
    city: string;
    town: string;
    fullAddress: string;
  };
}

const urlAgreeTerm = "/users/terms-agree";

const urlOrganizationProvinces = "/prefectures";
const urlGetAddressByZip = "https://api.zipaddress.net";
const urlOrganizationPreferences = "/referral-companies";

const urlTurnOnTOTPSecret = "/auth/turn-on-totp-secret";
const urlVerifyTOTP = "/auth/verify-totp";
const urlTurnOffTOTPSecret = "/auth/turn-off-totp-secret";

export default class OnboardingRepository extends Repository {
  // Terms
  agreeTerm = async (formValues: JSON) => {
    const data = await this.axios.put(`${urlAgreeTerm}`, formValues);
    if (data) {
      return data;
    }

    return false;
  };

  //Organization
  getProvinces = async () => {
    const data = await this.axios.get(`${urlOrganizationProvinces}`);
    if (data) {
      return data.data;
    }
    return [];
  };

  getAddressByZip = async (code: string) => {
    const { data } = await axios.get<AddressResponse>(urlGetAddressByZip, {
      params: {
        zipcode: code,
      },
    });
    if (data) {
      return data;
    }
    return false;
  };

  getPreferences = async () => {
    const data = await this.axios.get(`${urlOrganizationPreferences}`);
    if (data) {
      return data.data;
    }
    return [];
  };

  // TWO Factor

  turnOnTOTPSecret = async () => {
    const data = await this.axios.post(`${urlTurnOnTOTPSecret}`);
    if (data) return data.data;
    return false;
  };

  verifyTOTPSecret = async (form: FormContext) => {
    const data = await this.axios.post(
      `${urlVerifyTOTP}?authenticationCode=${form.values["authenticationCode"]}&totpSecret=${form.values["totpSecret"]}`
    );
    if (data) return data;
    return false;
  };

  turnOffTOTPSecret = async () => {
    const data = await this.axios.post(`${urlTurnOffTOTPSecret}`);
    if (data) return data.data;
    return false;
  };
}
