import { TemplateInformation } from '@/models/contracts/Information';
import Repository from "@/lib/https/repository";
import TemplateAndContract from "@/models/TemplateAndContract";

const urlFoldersAndContracts = "/folders-and-contracts";
const urlContractsBuiltinTemplates = "/contracts/builtin-templates";
const urlUsersSearchUsersInAllCompanies =
  "/users/search-users-for-signer-viewer";
const urlContracts = "/contracts";
const urlContractInformation = "/contract-information"
const urlContractsFullInformation = "/contracts/full-information";
const urlDeleteMany = `/contracts/delete-many`;

export default class TemplateRepository extends Repository {
  // digital/template/	list folders and templates	{{host}}/folders-and-contracts?isTemplateFolder=true
  getFoldersAndContracts = async () => {
    const { data } = await this.axios.get(`${urlFoldersAndContracts}`);
    if (data) return data;
    return {};
  };

  // digital/template/	list builtin templates	{{host}}/contracts/builtin-templates
  getContractsBuiltinTemplates = async () => {
    const { data } = await this.axios.get(`${urlContractsBuiltinTemplates}`);
    if (data) return data;
    return {};
  };

  // digital/template/	search for signers	{{host}}/users/search-users-for-signer-viewer?keyword=name
  getUsersSearchUsersInAllCompanies = async (keyword: string) => {
    const data = await this.axios.get(
      `${urlUsersSearchUsersInAllCompanies}?keyword=${encodeURIComponent(
        keyword
      )}`
    );
    if (data) {
      return data.data;
    }
    return [];
  };

  // digital/template/	create template	{{host}}/contracts
  createContracts = async (formValues: FormData) => {
    const data = await this.axios.post(`${urlContracts}`, formValues);
    if (data) {
      return data.data;
    }
    return false;
  };

  // digital/template/	get template (full information for update)	{{host}}/contracts/full-information/85b8f169-7116-4d72-b253-8a9cf5b72f4f

  getContractsFullInformation = async (id: string, accessCode = "") => {
    const { data } = await this.axios.get<TemplateAndContract>(
      `${urlContractsFullInformation}/${id}?accessCode=${accessCode}`
    );
    if (data) return data;

    return false;
  };

  // digital/template/	update template	{{host}}/contracts
  updateContracts = async (formValues: FormData) => {
    const { data } = await this.axios.put(`${urlContracts}`, formValues);
    if (data) {
      return data;
    }
    return false;
  };

  getContractInformation = async (contractId: string) => {
    const { data } = await this.axios.get(`${urlContractInformation}/${contractId}`);
    if (data) {
      return data;
    }
    return false;
  };

  updateContractInformation = async (contract: TemplateInformation) => {
    const { data } = await this.axios.put("/contract-information", contract);
    if (data) {
      return data;
    }
    return false;
  };

  deleteManyTemplate = async (payload: { ids: string[] }) => {
    if (payload) {
      const response = await this.axios.delete(urlDeleteMany, {
        data: payload,
      });
      if (response) return true;
      return false;
    }
  };

  removeDraftContract = async (draftContractId: string) => {
    try {
      const data = await this.axios.delete(
        `${urlContracts}/${draftContractId}`
      );
      if (data) return true;
      return false;
    } catch (e: any) {
      return e;
    }
  };
}
