import Repository from "@/lib/https/repository";

const urlCompanyEmailReceivers = "/company-notification-receivers/emails";
const urlContractNotification = "/contract-notifications";

// Notification User
const urlSearchUserNotificationReceivers =
  "/users/search-users-for-notification-receivers";
const urlCompaniesAddNotificationReceivers =
  "/companies/add-notification-receivers";
const urlCompanyNotificationReceivers = "/company-notification-receivers";

export default class NotificationRepository extends Repository {
  getCompanyEmailReceivers = async () => {
    const data = await this.axios.get(`${urlCompanyEmailReceivers}`);
    if (data) {
      return data.data;
    }
    return "";
  };

  getContractNotification = async (notificationID: string) => {
    const data = await this.axios.get(
      `${urlContractNotification}/${notificationID}`
    );
    if (data) {
      return data.data;
    }
    return "";
  };

  createContractNotification = async (payload: any) => {
    const data = await this.axios.post(`${urlContractNotification}`, payload);

    if (data) return data;
    return false;
  };

  updateContractNotification = async (payload: any) => {
    const data = await this.axios.put(`${urlContractNotification}`, payload);

    if (data) return data;
    return false;
  };

  deleteContractNotification = async (notificationID: string) => {
    const data = await this.axios.delete(
      `${urlContractNotification}/${notificationID}`
    );

    if (data) return data;
    return false;
  };

  getUserForNotificationReceivers = async (keyword: string) => {
    const data = await this.axios.get(
      `${urlSearchUserNotificationReceivers}?keyword=${encodeURIComponent(
        keyword
      )}`
    );
    if (data) {
      return data.data.data;
    }
    return "";
  };

  addCompanyNotificationReceivers = async (notificationReceivers: []) => {
    await this.axios.post(`${urlCompaniesAddNotificationReceivers}`, {
      notificationReceivers: notificationReceivers,
    });
  };

  deleteCompanyNotificationReceivers = async (receiver: string) => {
    await this.axios.delete(`${urlCompanyNotificationReceivers}/${receiver}`);
  };

  getCompanyNotificationReceivers = async () => {
    const data = await this.axios.get(`${urlCompanyNotificationReceivers}`);
    if (data) {
      return data.data.data;
    }
    return "";
  };
}
