import Repository from "@/lib/https/repository";

const rejectSignUrl = "/contracts/reject";
const requestSignUrl = "/contracts/request-signing-verification-code";
const signUrl = "/contracts/sign";

const signFileUrl = "/contracts/sign-by-xid";
const checkSignatureStatusUrl = "/contracts/check-xid-signature-status";

export default class SignRepository extends Repository {
  rejectSign = async (contractId: string) => {
    const data = await this.axios.post(`${rejectSignUrl}/${contractId}`);

    if (data) return true;
    return false;
  };

  rejectSignGuest = async () => {
    const data = await this.axios.post(`/guest${rejectSignUrl}`);

    if (data) return true;
    return false;
  };

  requestSignVerificationCode = async (contractId: string) => {
    const data = await this.axios.post(`${requestSignUrl}/${contractId}`);

    if (data) return true;
    return false;
  };

  requestSignGuest = async () => {
    const data = await this.axios.post(`/guest${requestSignUrl}`);

    if (data) return true;
    return false;
  };

  signContract = async (contractId: string, accessCode = "", payload: any) => {
    const data = await this.axios.post(
      `${signUrl}/${contractId}?accessCode=${accessCode}`,
      payload
    );

    if (data) return true;
    return false;
  };

  signContractGuest = async (accessCode = "", payload: any) => {
    const data = await this.axios.post(
      `/guest${signUrl}?accessCode=${accessCode}`,
      payload
    );

    if (data) return true;
    return false;
  };

  signFile = async (
    contractId: string,
    fileId: string,
    accessCode: string,
    payload: any
  ) => {
    const data = await this.axios.post(
      `${signFileUrl}/${contractId}/${fileId}?accessCode=${accessCode}`,
      payload
    );

    if (data) return data.data;
    return false;
  };

  signFileGuest = async (fileId: string, accessCode: string, payload: any) => {
    const data = await this.axios.post(
      `/guest${signFileUrl}/${fileId}?accessCode=${accessCode}`,
      payload
    );

    if (data) return data.data;
    return false;
  };

  checkSignatureStatus = async (contractId: string, fileId: string) => {
    const data = await this.axios.get(
      `${checkSignatureStatusUrl}/${contractId}/${fileId}`
    );

    if (data) return data.data;
    return false;
  };

  checkSignatureGuestStatus = async (fileId: string) => {
    const data = await this.axios.get(
      `/guest${checkSignatureStatusUrl}/${fileId}`
    );

    if (data) return data.data;
    return false;
  };
}
