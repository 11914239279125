import Repository from "@/lib/https/repository";
import moment from "moment";

const urlProfile = "/users/profile";
const urlFullProfile = "/users/full-profile";
const urlMemberList = "/users";
const urlInviteMemberList = "/company-member-invitations";
const urlReset2FA = "/users/reset-2fa";
const urlBlockMember = "/users/block";
const urlUnblockMember = "/users/unblock";
const urlPendingResend = "/company-member-invitations/invite/resend";
const urlMemberGroupList = "/users/list-groups";
const urlUpdateProfilePicture = "/users/profile-picture";
const urlUpdateFullName = "/users/full-name";
const urlUpdatePosition = "/users/position";
const urlUpdatePhoneNumber = "/users/phone-number";
const urlUpdateEmail = "/users/email";
const urlUpdatePassword = "/users/password";
const urlAddMember = "/company-member-invitations/invite";
const urlDeleteApprovers = "/user-approvers";
const urlUpdateMemberRole = "/users/role";

export default class MemberRepository extends Repository {
  // Profile
  getProfile = async () => {
    const data = await this.axios.get(`${urlProfile}`);
    if (data) {
      return data.data;
    }
    return "";
  };

  updateProfile = async (formValues: FormData) => {
    const data = await this.axios.put(`${urlProfile}`, formValues);
    if (data) return data;
    return false;
  };

  getMemberList = async (
    page = 1,
    pageSize = 10,
    keyword = "",
    includeBlockedUser = false,
    bookmark = "",
  ) => {
    const data = await this.axios.get(
      `${urlMemberList}?page=${page}&pageSize=${pageSize}&keyword=${encodeURIComponent(
        keyword
      )}&includeBlockedUser=${includeBlockedUser}&bookmark=${bookmark}`
    );
    if (data) {
      return data.data;
    }
    return [];
  };

  getInviteMemberList = async (page = 1, pageSize = 10, keyword = "", bookmark = "") => {
    const data = await this.axios.get(
      `${urlInviteMemberList}?page=${page}&pageSize=${pageSize}&keyword=${encodeURIComponent(keyword)}&bookmark=${bookmark}`);
    if (data) {
      return data.data;
    }
    return [];
  };

  getInvitingMember = async (invitingMeberId: string) => {
    const data = await this.axios.get(
      `${urlInviteMemberList}/${invitingMeberId}`
    );
    if (data) {
      return data.data;
    }
    return "";
  };

  updateNameInvitingMember = async (name: string, invitingMemberId: string) => {
    const data = await this.axios.put(
      `${urlInviteMemberList}/name`,
      JSON.stringify({
        name: name,
        id: invitingMemberId,
      })
    );
    if (data) {
      return true;
    }
    return false;
  };

  updateMailInvitingMember = async (mail: string, invitingMemberId: string) => {
    const data = await this.axios.put(`${urlInviteMemberList}/email`, {
      email: mail,
      id: invitingMemberId,
    });
    if (data) {
      return true;
    }
    return false;
  };

  resetMember2FA = async (memberID: string) => {
    const data = await this.axios.put(`${urlReset2FA}/${memberID}`);
    if (data) {
      return data;
    }
    return false;
  };

  blockMember = async (memberID: string) => {
    const data = await this.axios.put(`${urlBlockMember}/${memberID}`);
    if (data) {
      return data;
    }
    return false;
  };

  unblockMember = async (memberID: string) => {
    const data = await this.axios.put(`${urlUnblockMember}/${memberID}`);
    if (data) {
      return data;
    }
    return false;
  };

  deleteMember = async (memberID: string) => {
    const data = await this.axios.delete(`${urlMemberList}/${memberID}`);
    if (data) {
      return data;
    }
    return false;
  };

  pendingResend = async (memberID: string) => {
    const data = await this.axios.post(`${urlPendingResend}/${memberID}`);
    if (data) {
      return data;
    }
    return false;
  };

  pendingCancel = async (memberID: string) => {
    const data = await this.axios.delete(`${urlInviteMemberList}/${memberID}`);
    if (data) {
      return data;
    }
    return false;
  };

  getMemberProfile = async (memberID: string | string[]) => {
    const data = await this.axios.get(`${urlFullProfile}/${memberID}`);
    if (data) {
      return data.data;
    }
    return false;
  };

  getMemberGroupList = async (memberID: string | string[]) => {
    const data = await this.axios.get(`${urlMemberGroupList}/${memberID}`);
    if (data) {
      return data.data;
    }
    return [];
  };

  updateProfilePicture = async (memberID: string, formValues: FormData) => {
    const { data } = await this.axios.put(
      memberID != ""
        ? `${urlUpdateProfilePicture}/${memberID}`
        : `${urlUpdateProfilePicture}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  updateFullName = async (memberID: string, formValues: FormData) => {
    const { data } = await this.axios.put(
      `${urlUpdateFullName}/${memberID}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  updatePosition = async (memberID: string, formValues: FormData) => {
    const { data } = await this.axios.put(
      `${urlUpdatePosition}/${memberID}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  updatePhoneNumber = async (memberID: string, formValues: FormData) => {
    const { data } = await this.axios.put(
      `${urlUpdatePhoneNumber}/${memberID}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  updateEmail = async (memberID: string, formValues: FormData) => {
    let url = `${urlUpdateEmail}/${memberID}`;
    if (memberID == "") {
      url = urlUpdateEmail;
    }
    const { data } = await this.axios.put(url, formValues);
    if (data) {
      return data;
    }
    return false;
  };

  updatePassword = async (memberID: string, formValues: FormData) => {
    const { data } = await this.axios.put(
      `${urlUpdatePassword}/${memberID}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  addMember = async (payload: any) => {
    const data = await this.axios.post(`${urlAddMember}`, payload);

    if (data) return data;
    return false;
  };

  getApproversList = async (
    page = 1,
    pageSize = 10,
    keyword = "",
    excludeApproversOfUserId: string
  ) => {
    const data = await this.axios.get(
      `${urlMemberList}?page=${page}&pageSize=${pageSize}&keyword=${encodeURIComponent(
        keyword
      )}&excludeApproversOfUserId=${excludeApproversOfUserId}`
    );
    if (data) {
      return data.data;
    }
    return [];
  };

  deleteApprovers = async (userID: string, approverID: string) => {
    const data = await this.axios.delete(
      `${urlDeleteApprovers}/${userID}/${approverID}`
    );

    if (data) return data;
    return false;
  };

  updateMemberRole = async (userID: string, role: number) => {
    const formData = new FormData();
    formData.append("role", String(role));
    const data = await this.axios.put(
      `${urlUpdateMemberRole}/${userID}`,
      formData
    );

    if (data) return data;
    return false;
  };

  updateInviteMemberRole = async (userID: string, role: number) => {
    const data = await this.axios.put(`${urlInviteMemberList}/role`, {
      role: role,
      id: userID,
    });
    if (data) {
      return true;
    }
    return false;
  }

}
