import Repository from "@/lib/https/repository";

const urlRequestForgotPasswordCode = "/auth/request-forgot-password-code";
const urlUpdatePassword = "/auth/update-password-by-forgot-password-code";

export default class ForgotPasswordRepository extends Repository {
  requestForgotPasswordCode = async (formValues: FormData) => {
    const data = await this.axios.post(
      `${urlRequestForgotPasswordCode}`,
      formValues
    );
    if (data) {
      return true;
    }
    return false;
  };

  updateForgotPassword = async (formValues: FormData) => {
    const data = await this.axios.post(`${urlUpdatePassword}`, formValues);
    if (data) {
      return data;
    }
    return false;
  };
}
