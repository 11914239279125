import Repository from "@/lib/https/repository";

//Dashboard
const urlDashboard = "/dashboard";
const urlStatistics = "/statistics";
const urlElapsedTime = "/contracts/history/elapsed-time";
const urlRanking = "/ranking";

//Account
const urlFullProfile = "/users/full-profile";
const urlUpdatePassword = "/users/password";
const urlReceiveNotification = "/users/receive-notification";
const urlAddApprovers = "/users/add-approvers";
const urlDeleteApprovers = "/user-approvers";
const urlResendVerificationCode = "/users/email/resend-verification-code";
const urlCancelEmailChanging = "/users/email/cancel-changing";
const urlLinkXID = "/users/link-xid";

export default class AccountRepository extends Repository {
  //Dashboard
  getDashboard = async () => {
    const data = await this.axios.get(`${urlDashboard}`);
    if (data) return data.data;
    return false;
  };

  getStatistics = async () => {
    const data = await this.axios.get(`${urlStatistics}`);
    if (data) return data.data;
    return false;
  };

  getElapsedTime = async (count = 10 as number) => {
    const data = await this.axios.get(`${urlElapsedTime}/${count}`);
    if (data) return data.data;
    return false;
  };

  getRanking = async (type: string) => {
    const data = await this.axios.get(`${urlRanking}/${type}`);
    if (data) return data.data;
    return false;
  };

  // Profile
  getFullProfile = async () => {
    const data = await this.axios.get(`${urlFullProfile}`);
    if (data) {
      return data.data;
    }
    return "";
  };

  linkXIDAccount = async (): Promise<void> => {
    await this.axios.put(`${urlLinkXID}`).then((response) => {
      window.open(response.data.redirectUrl, "_self");
    });
  };

  updateAccountPassword = async (formValues: FormData) => {
    const { data } = await this.axios.put(`${urlUpdatePassword}`, formValues);
    if (data) {
      return data;
    }
    return false;
  };

  updateReceiveNotification = async (formValues: FormData) => {
    const { data } = await this.axios.put(
      `${urlReceiveNotification}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  addApprovers = async (userID: string, payload: any) => {
    const data = await this.axios.post(`${urlAddApprovers}/${userID}`, payload);

    if (data) return data;
    return false;
  };

  deleteApprovers = async (approverID: string) => {
    const data = await this.axios.delete(`${urlDeleteApprovers}/${approverID}`);

    if (data) return data;
    return false;
  };

  resendPendingEmail = async () => {
    const data = await this.axios.post(`${urlResendVerificationCode}`);

    if (data) return data;
    return false;
  };

  cancelEmailChanging = async () => {
    const data = await this.axios.post(`${urlCancelEmailChanging}`);

    if (data) return data;
    return false;
  };
}
