import Repository from "./repository";

const repositories = [] as Array<Repository>;

type Constructor<T> = new (...args: any[]) => T;

const RepositoryFactory = {
  getRepository<R extends Repository>(type: Constructor<R>): R {
    const repository = repositories.find((a) => a instanceof type);
    if (repository) return repository as R;
    else {
      const newRepository = new type();
      repositories.push(newRepository);
      return newRepository;
    }

    // if (!repositories.has(name)) {
    //   switch (type) {
    //     case AuthRepository: {
    //       repositories.set(name, new AuthRepository());
    //       break;
    //     }
    //     case ForgotPasswordRepository: {
    //       repositories.set(name, new ForgotPasswordRepository());
    //       break;
    //     }
    //     case OnboardingRepository: {
    //       repositories.set(name, new OnboardingRepository());
    //       break;
    //     }
    //     case CompanyRepository: {
    //       repositories.set(name, new CompanyRepository());
    //       break;
    //     }
    //     case MemberRepository: {
    //       repositories.set(name, new MemberRepository());
    //       break;
    //     }
    //     case GroupRepository: {
    //       repositories.set(name, new GroupRepository());
    //       break;
    //     }
    //     case TemplateRepository: {
    //       repositories.set(name, new TemplateRepository());
    //       break;
    //     }
    //     case FolderRepository: {
    //       repositories.set(name, new FolderRepository());
    //       break;
    //     }
    //     case ContractRepository: {
    //       repositories.set(name, new ContractRepository());
    //       break;
    //     }
    //     case AccountRepository: {
    //       repositories.set(name, new AccountRepository());
    //       break;
    //     }
    //     case ContactRepository: {
    //       repositories.set(name, new ContactRepository());
    //       break;
    //     }
    //     case NotificationRepository: {
    //       repositories.set(name, new NotificationRepository());
    //       break;
    //     }
    //     case InvoiceRepository: {
    //       repositories.set(name, new InvoiceRepository());
    //       break;
    //     }
    //     case TransferRepository: {
    //       repositories.set(name, new TransferRepository());
    //       break;
    //     }
    //     case SignRepository: {
    //       repositories.set(name, new SignRepository());
    //       break;
    //     }
    //     default:
    //       break;
    //   }
    // }
    // return repositories.get(name);
  },
};

export default RepositoryFactory;
