import Repository from "@/lib/https/repository";

const urlCreateCompany = "/companies";
const urlCompanyInformation = "/companies/information";
const urlUpdateLogo = "/companies/logo";
const urlUpdateName = "/companies/name";
const urlUpdatePhoneNumber = "/companies/phone-number";
const urlUpdateRepresentativeName = "/companies/representative-name";
const urlUpdateReferralPersonName = "/companies/referral-person-name";
const urlUpdateAddress = "/companies/address";
const urlUpdateControlNumber = "companies/control-number";
const urlUpdateReferralCompany = "/companies/referral-company";
const urlUpdateTwoFARequired = "/companies/is-2fa-required";
const urlUpdateMailAllow = "/companies/is-mail-notification-allowed";
const urlUpdateImprint = "/companies/imprint";

const urlCheckName = "/companies/check-name";

export default class CompanyRepository extends Repository {
  createCompany = async (formValues: FormData) => {
    const data = await this.axios.post(`${urlCreateCompany}`, formValues);
    if (data) {
      return data;
    }
    return false;
  };

  getCompany = async () => {
    const { data } = await this.axios.get(`${urlCompanyInformation}`);
    if (data) return data;
    return {};
  };

  updateCompanyLogo = async (formValues: FormData) => {
    const { data } = await this.axios.put(`${urlUpdateLogo}`, formValues);
    if (data) {
      return data;
    }
    return false;
  };

  updateCompanyImprint = async (formValues: FormData) => {
    const { data } = await this.axios.put(`${urlUpdateImprint}`, formValues);
    if (data) {
      return data;
    }
    return false;
  };

  updateCompanyName = async (formValues: FormData) => {
    const { data } = await this.axios.put(`${urlUpdateName}`, formValues);
    if (data) {
      return data;
    }
    return false;
  };

  updateCompanyPhoneNumber = async (formValues: FormData) => {
    const { data } = await this.axios.put(
      `${urlUpdatePhoneNumber}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  updateRepresentativeName = async (formValues: FormData) => {
    const { data } = await this.axios.put(
      `${urlUpdateRepresentativeName}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  updateReferralPersonName = async (formValues: FormData) => {
    const { data } = await this.axios.put(
      `${urlUpdateReferralPersonName}`,
      formValues
    );
    if (data) {
      return data;
    }
    return false;
  };

  updateAddress = async (formValues: FormData) => {
    const { data } = await this.axios.put(`${urlUpdateAddress}`, formValues);
    if (data) {
      return data;
    }
    return false;
  };

  updateReferralCompany = async (form: FormData) => {
    const { data } = await this.axios.put(`${urlUpdateReferralCompany}`, form);
    if (data) {
      return data;
    }
    return false;
  };

  updateControlNumber = async (form: FormData) => {
    const { data } = await this.axios.put(`${urlUpdateControlNumber}`, form);

    if (data) return data;
    return false;
  };

  updateTwoFARequired = async (formValues: FormData) => {
    const { data } = await this.axios.put(
      `${urlUpdateTwoFARequired}`,
      formValues
    );
    if (data) {
      data.is2FARequired = !!data.is2FARequired;
      return data;
    }
    return false;
  };

  updateMailAllow = async (formValues: FormData) => {
    const { data } = await this.axios.put(`${urlUpdateMailAllow}`, formValues);
    if (data) {
      data.isMailNotificationAllowed = !!data.isMailNotificationAllowed;
      return data;
    }
    return false;
  };

  checkExistName = async (name: string) => {
    const { data } = await this.axios.get(`${urlCheckName}?name=${name}`);
    if (data) return data;
    return false;
  };
}
